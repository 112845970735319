<template>
  <div class="address-tabs">
    <p class="body-text">{{ $t("guest.address_description") }}</p>
    <z-input
      v-if="!config.addressData?.zipcode?.default && guest.countryId === 'BR'"
      key="with-mask"
      inputmode="numeric"
      mask="#####-###"
      data-cy="zipcode"
      hide-details="auto"
      :loading="isLoadingCep"
      :label="$t('guest.zipCode')"
      :value="guest.zipCode"
      :error-messages="getErrorMessages(v$.zipCode)"
      @input="$emit('change', { zipCode: $event })"
    />

    <z-input
      v-else-if="
        !config.addressData?.zipcode?.default && guest.countryId !== 'BR'
      "
      key="without-mask"
      inputmode="numeric"
      data-cy="zipcode"
      :label="$t('guest.zipCode')"
      :value="guest.zipCode"
      :error-messages="getErrorMessages(v$.zipCode)"
      @input="$emit('change', { zipCode: $event })"
    />
    <div v-if="!config.addressData.zipcode.default" class="cep-link">
      <a
        class="body-text"
        style="text-decoration: underline !important; font-size: 0.85rem"
        @click="$emit('click:cep')"
      >
        {{ $t("guest.i_dont_know_my_zipcode") }}
      </a>
    </div>
    <z-input
      v-if="!config.addressData.address.default"
      :mask="addressMask(100)"
      hide-details="auto"
      data-cy="address"
      :loading="isLoadingCep"
      :label="$t('guest.address')"
      :value="guest.address"
      :error-messages="getErrorMessages(v$.address)"
      @input="$emit('change', { address: $event })"
    />
    <z-autocomplete
      v-if="!config.addressData.city.default"
      data-cy="city"
      hide-details="auto"
      item-text="name"
      item-value="id"
      :loading="isLoadingCep"
      :label="$t('guest.city')"
      :items="cities"
      :value="guest.cityId"
      :error-messages="getErrorMessages(v$.cityId)"
      @change="$emit('change', { cityId: $event })"
    />
    <z-autocomplete
      v-if="!config.addressData.state.default"
      data-cy="state"
      hide-details="auto"
      item-text="uf"
      item-value="id"
      :loading="isLoadingCep"
      :label="$t('guest.state')"
      :items="states"
      :value="guest.stateId"
      :error-messages="getErrorMessages(v$.stateId)"
      @change="$emit('change', { stateId: $event })"
    />
    <z-input
      v-if="!config.addressData.neighborhood.default"
      hide-details="auto"
      data-cy="neighborhood"
      :loading="isLoadingCep"
      :label="$t('guest.neighborhood')"
      :value="guest.neighborhood"
      :error-messages="getErrorMessages(v$.neighborhood)"
      @input="$emit('change', { neighborhood: $event })"
    />
    <z-input
      v-if="!config.addressData.addressNumber.default"
      data-cy="address-number"
      inputmode="numeric"
      type="number"
      hide-details="auto"
      :label="$t('guest.address_number')"
      :value="guest.addressNumber"
      :error-messages="getErrorMessages(v$.addressNumber)"
      @input="$emit('change', { addressNumber: $event })"
    />
    <z-input
      v-if="!config.addressData?.addressComplement?.default"
      hide-details="auto"
      :label="$t('guest.address_complement')"
      :value="guest.addressComplement"
      :error-messages="getErrorMessages(v$.addressComplement)"
      @input="$emit('change', { addressComplement: $event })"
    />
    <z-btn
      data-cy="next-btn"
      class="next-btn"
      primary
      width="100%"
      :text="$t('guest.next')"
      :disabled="v$.$dirty && v$.$invalid"
      @click="onNext"
    />
    <z-btn width="100%" :text="$t('guest.back')" @click="$emit('click:back')" />
  </div>
</template>

<script>
import { useVuelidate } from "@vuelidate/core"
import { inject, onMounted, toRefs } from "vue"

import { addressMask } from "@/consts"

import { usePersonalInfoValidations } from "../useValidations"

/**
 * Componente Mobile Only
 */
export default {
  props: {
    guest: {
      type: Object,
      required: true,
    },
    states: {
      type: Array,
      default: () => [],
    },
    cities: {
      type: Array,
      default: () => [],
    },
    config: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const isLoadingCep = inject("isLoadingCep")
    const { guest } = toRefs(props)
    const { addressRules, getErrorMessages } = usePersonalInfoValidations({
      guest,
      config: props.config,
    })

    const v$ = useVuelidate(addressRules, guest)

    const onNext = () => {
      v$.value.$touch()

      if (!v$.value.$invalid) {
        emit("click:next")
      }
    }

    onMounted(() => {
      v$.value.$reset()
    })

    return {
      v$,
      getErrorMessages,
      onNext,
      isLoadingCep,
      addressMask,
    }
  },
}
</script>

<style lang="scss" scoped>
.address-tabs {
  display: grid;
  gap: 15px;
}

.cep-link {
  width: 100%;
  text-align: end;
}
</style>
